import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '../product.service';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-curl',
  templateUrl: './curl.component.html',
  styleUrls: ['./curl.component.css']
})
export class CurlComponent implements OnInit {
  languageList = [];
  curlForm: FormGroup;
  city;
  image = '../../assets/img/angular.png';
  result = '';
  description = `Convert curl command to Python, Node.js, R, PHP, Strest, Go, JSON, Rust - Online Dev Tools`;
  keyword = `python curl , curl to python , curl python , curl to php , curl online , online curl , curl to json , Online Dev Tools`;
  heading = `Convert a cURL Command to api Request`;
  constructor(private productService: ProductService ,
     private title: Title, private meta: Meta ) {
      this.title.setTitle(this.heading);
      this.meta.updateTag({ name: 'description', content: this.description });
      this.meta.updateTag({ name: 'keywords', content: this.keyword });
      }

  ngOnInit() {
    this.curlForm = new FormGroup({
      'language': new FormControl(null, Validators.required),
      'curlcode': new FormControl(null, Validators.required),
    });
    // this.languageList.push({ label: 'Choose your language', value: '' });
    this.languageList.push({ label: 'Python', value: 'py' });
    this.languageList.push({ label:  'Node.js', value: 'node' });
    this.languageList.push({ label: 'JSON', value: 'json' });
    this.languageList.push({ label: 'PHP', value: 'php' });
    this.languageList.push({ label: 'Go', value: 'go' });
    this.languageList.push({ label: 'R', value: 'r' });
    this.languageList.push({ label: 'Rust', value: 'rust' });
    this.languageList.push({ label: 'Strest', value: 'strest' });

    this.curlForm.get('language').setValue('node');
    this.curlForm.get('curlcode').setValue('curl -v http://www.example.com');
  }
  onSubmit() {
    this.result = '';
    const payload = {
      code : this.curlForm.get('curlcode').value,
      language : this.curlForm.get('language').value,
    };
    this.productService.getCurl(payload).subscribe((res: any) => {
      if (res) {
       this.result = res;
      }
    });
  }
  clipboardCopy(e) {
    const copyText: any = document.getElementById('myInput');
    if (e === 'copy') {
      /* Select the text field */
      copyText.select();
      /* Copy the text inside the text field */
      document.execCommand('copy');
    } else {
      copyText.select();
    }
  }
  changeLang(e) {

  }
}
