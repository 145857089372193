import { BrowserModule , Title} from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';


import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AceEditorModule } from 'ng2-ace-editor';


import { FormatterComponent } from './formatter/formatter.component';
import { BeautifierComponent } from './beautifier/beautifier.component';
import { CrontabComponent } from './crontab/crontab.component';
import { CurlComponent } from './curl/curl.component';
import { EncodeComponent } from './encode/encode.component';
import { RegularexpressionComponent } from './regularexpression/regularexpression.component';
import { ConverterComponent } from './converter/converter.component';
import { ProductService } from './product.service';
import { HttpClientModule } from '@angular/common/http';


import { NewsfeedComponent } from './newsfeed/newsfeed.component';
import { FooterComponent } from './footer/footer.component';
import { FixedsidebarComponent } from './fixedsidebar/fixedsidebar.component';
import { MiniferComponent } from './minifer/minifer.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PureJavascriptComponent } from './privacypolicy/purejavascript.component';
import { HomeComponent } from './home/home.component';
import { UnicodeConverterComponent } from './unicode-converter/unicode-converter.component';
import { YoutubeVideoDownloaderComponent } from './youtube-video-downloader/youtube-video-downloader.component';


@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NavbarComponent,
    FormatterComponent,
    BeautifierComponent,
    CrontabComponent,
    CurlComponent,
    EncodeComponent,
    RegularexpressionComponent,
    ConverterComponent,
    NewsfeedComponent,
    FooterComponent,
    FixedsidebarComponent,
    MiniferComponent,
    AboutusComponent,
    PureJavascriptComponent,
    HomeComponent,
    UnicodeConverterComponent,
    YoutubeVideoDownloaderComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AceEditorModule,
    RouterModule.forRoot(AppRoutes),
  ],
  providers: [ProductService , Title],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
