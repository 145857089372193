import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-crontab',
  templateUrl: './crontab.component.html',
  styleUrls: ['./crontab.component.css']
})
export class CrontabComponent implements OnInit {
  image = '../../assets/img/angular.png';
  title = `CronJob Setup - Kubernetes,Docker,Laravel,Ansible,Quartz,PHP`;
  description = `You Can Easily Schedule Cronjob in Kubernetes, Docker, Laravel, Ansible, Quartz, PHP with Cron Example and Explanation`;
  constructor(private meta: Meta, private head: Title) {
    this.head.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: this.description });
  }

  ngOnInit() {
  }

}
