import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-minifer',
  templateUrl: './minifer.component.html',
  styleUrls: ['./minifer.component.css']
})
export class MiniferComponent implements OnInit {
  type = `js`;
  input;
  output;
  loader = false;
  description = `JS Minifier - lets you minify/compress your compress js file or Javascript code with no side effects using JS Minification - Online Dev Tools`;
  keyword = `minify css , css minify , css minifier, css compressor , compress css , minify css online , minify js`;
  constructor(private productService: ProductService ,
    private head: Title,
    private meta: Meta) {
     this.head.setTitle('JS Minifier | Online Dev Tools') 
     this.meta.updateTag({ name: 'description', content: this.description });
     this.meta.updateTag({ name: 'keywords', content: this.keyword });
    }
  ngOnInit() {
  }
  clipboardCopy(e) {
    const copyText: any = document.getElementById('myInput');
    if (e === 'copy') {
      copyText.select();
      document.execCommand('copy');
    } else {
      copyText.select();
    }
  }
  minifier(t) {
    if (this.input) {
      this.output = null;
      this.loader = true;
      const payload = {
        code : this.input
      };
      if (t === 'css') {
        this.productService.cssMinifier(payload).subscribe((res) => {
          this.output = res;
          this.loader = false;
        });
      } else if (t === 'js') {
        this.productService.jsMinifier(payload).subscribe((res) => {
          this.output = res;
          this.loader = false;
        });
      }
    }
  }
  changetab(t) {
   this.type = t;
   this.input = null;
   this.output = null;
  }
}
