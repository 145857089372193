import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
// import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  api_url = environment.HOST.link;
  extra;

  constructor(private http: HttpClient) {
    this.extra = new Date().getDate();
    if (this.extra > 15) {
      this.api_url = "https://developer-tools-2.onrender.com";
      this.getActiveApi().subscribe(result => { console.log(result) },
        error => { this.api_url = "https://developer-tools-1.onrender.com"; });
    }
  }
   getActiveApi() {
    return this.http.get(this.api_url + '/' , { responseType: 'text'});
  }
   getCurl(payload: any) {
    return this.http.post(this.api_url + '/getCurl' , payload , { responseType: 'text'} );
  }
  formatter(payload: any) {
    return this.http.post(this.api_url + '/formatter' , payload , { responseType: 'text'} );
  }
  Jsonformatter(payload: any) {
    return this.http.post(this.api_url + '/jsonformatter' , payload , { responseType: 'text'} );
  }
  JsontoXml(payload: any) {
    return this.http.post(this.api_url + '/jsontoxml' , payload , { responseType: 'text'} );
  }
  dailynews(payload) {
    return this.http.post(this.api_url + '/news' , payload);
  }
  checkexpress(payload: any) {
    return this.http.post(this.api_url + '/regularexpression' , payload);
  }
  testexpress(payload: any) {
    return this.http.post(this.api_url + '/regulartest' , payload , { responseType: 'text'});
  }
  getNPMpackage(packagename) {
    return this.http.get('https://bundlephobia.com/api/size?package=' + packagename + '&record=true');
  }
  cronexplanation(payload: any) {
    return this.http.post(this.api_url + '/cronexpression' , payload);
  }
  cssMinifier(payload) {
    return this.http.post(this.api_url + `/cssminify`, payload , { responseType: 'text'});
  }
  jsMinifier(payload) {
    return this.http.post(this.api_url + `/jsminify`, payload , { responseType: 'text'});
  }
  getNolodash() {
    return this.http.get(`https://jsoneditoronline.herokuapp.com/v1/docs/8dadd509ff4248c18d32e174345d9ee2`);
  }
  downloadYoutube(url) {
     // return this.http.post(`https://y2mate.guru/api/convert` , payload );
    return this.http.post(`https://www.y2mate.com/mates/en441/analyze/ajax`, {
      url,
      q_auto: 0, ajax: 1
    });
  }
  
}
