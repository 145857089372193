import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  image = '../../assets/img/angular.png';

  description = `This is a collection of web developer tools for programmers to stay productive in their work or web development process. Find you are frequently used Devtools`;

  keyword = `developer tools, devtools, web developer tools ,web development tools`;

  heading = `Web Developer Tools - Online Dev Tools`;

  constructor(private title: Title, private meta: Meta) {
      this.title.setTitle(this.heading);
      this.meta.updateTag({ name: 'description', content: this.description });
      this.meta.updateTag({ name: 'keywords', content: this.keyword });
    }

  ngOnInit() {
  }
  goTo(newTitle) {
    if (newTitle === 'JWT') {
      this.onNavigate('https://www.jstoolset.com/jwt');
    }
    if (newTitle === 'Diff') {
      this.onNavigate('https://diffviewer.onlinedevtools.in');
    }
  }
  onNavigate(url) {
    window.open(url , '_blank');
  }
}
