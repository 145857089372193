import { Component , OnInit } from '@angular/core';
import { ProductService } from './product.service';
import { MetaService } from './metaService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'developapp';
  isMobile = false;
    ngOnInit() {
      if (document.documentElement.clientWidth > 768) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    }
    constructor(private product: ProductService , private metaService : MetaService) {
      this.metaService.createCanonicalURL();
      this.product.getActiveApi().subscribe((res: any) => {
      });
    }
    checkpath() {
      if (window.location.pathname === '/youtubefreedownload') return true;
      return false;
}
}