import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-beautifier',
  templateUrl: './beautifier.component.html',
  styleUrls: ['./beautifier.component.css']
})
export class BeautifierComponent implements OnInit {
  input;
  output;
  notfound;
  loader = false;
  image = '../../assets/img/angular.png';
  title = `NPM package size analyzer`;
  description = `NPM package size analyzer helps you find your npm  package size , performance and download speed .`;
  keyword = 'npm package size , size of npm package , npm size , npm package performance , npm speed , npm analyzer';

  constructor(private productService: ProductService , private meta: Meta ,
    private head: Title ) {
      this.head.setTitle(this.title);
      this.meta.updateTag({ name: 'description', content: this.description });
      this.meta.updateTag({ name: 'keywords', content: this.keyword });
    }

  ngOnInit() {
  }
  searchPackage() {
    this.notfound = null;
    this.output = null;
      if (this.input) {
        this.loader = true;
        this.productService.getNPMpackage(this.input).subscribe((res) => {
          this.output = res;
          this.loader = false;
          if (this.output  && this.output.dependencySizes && this.output.dependencySizes.length) {
             const total = this.output.dependencySizes.map(this.amount).reduce(this.sum);
            for (let index = 0; index < this.output.dependencySizes.length; index++) {
              const element = (this.output.dependencySizes[index].approximateSize / total) * 100;
              this.output.dependencySizes[index].per = Math.round(element);
            }
          }
        } , (err) => {
          this.notfound = true;
          this.loader = false;
        });
      } else {
        this.output = {};
        this.loader = false;
      }
  }
  amount(item) {
    return item.approximateSize;
  }

  sum(prev, next) {
    return prev + next;
  }
}
