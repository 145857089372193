import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-encode',
  templateUrl: './encode.component.html',
  styleUrls: ['./encode.component.css']
})
export class EncodeComponent implements OnInit {
  
  title = 'How To Perform a Post HTTP Request Using cURL - Curl Post';
  isMobile = false;
  image = '../../assets/img/angular.png';
  description = `You can easily perform a POST request with cURL Command. We Provide a Sample Post Request using cURL with Explanation - Online Dev Tools.`;

  keyword = `curl post, curl post request , curl request`;

  constructor(private meta: Meta , private head: Title) {
    this.head.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: this.description });
    this.meta.updateTag({ name: 'keywords', content: this.keyword });
   }

  ngOnInit() {
  }
}
