import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-video-downloader',
  templateUrl: './youtube-video-downloader.component.html',
  styleUrls: ['./youtube-video-downloader.component.css']
})
export class YoutubeVideoDownloaderComponent implements OnInit {
  url;
  output;
  image = '../../assets/img/angular.png';
  loader = false;
  constructor(private productService: ProductService ,
    private meta: Meta , private title : Title) {
     this.title.setTitle(`Youtube Free Video Downloader For PC and Mobile.`);
     this.meta.updateTag({ name: 'description', content: `Download Free Video and Audio from Youtube With Various Formats like Mp3,Mp4 and also We Provide User Friendly Experience to Download From the URL in Online`});
    }

  ngOnInit() {
    this.url= '';
  }
  downloadYoutubeVideo(url) {
    if (this.url) {
      this.loader = true;
      this.output = null;
      this.productService.downloadYoutube(url).subscribe((res: any) => {
        this.output = res && res.formats;
        this.loader = false;
      }, (err) => {
        this.loader = false;
        alert('Something Went Wrong or Enter Vaild URL');
      });
    } else {
      alert('Kindly Enter Valid URL');
    }
  }

}
