import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-unicode-converter',
  templateUrl: './unicode-converter.component.html',
  styleUrls: ['./unicode-converter.component.css']
})
export class UnicodeConverterComponent implements OnInit {
  text = "Hello Welcome";
  decimal;
  utf8;
  utf8text;
  utf32;
  utf16;
  image = '../../assets/img/angular.png';

  heading = `Convert a Unicode to UTF 8, UTF-16, Text , String, HTML`;

  description = `Convert Your Unicode Characters to UTF-8, UTF-16, UTF-32, Decimal, Text, String, HTML, and URL Format  - Unicode Converter Allows You to Easily Convert Online.`;
  
  keyword = `Unicode Characters , Unicode Convert`;
  constructor(private title: Title, private meta: Meta) { 
    this.title.setTitle(this.heading);
    this.meta.updateTag({ name: 'description', content: this.description });
    this.meta.updateTag({ name: 'keywords', content: this.keyword });
  }

  ngOnInit() {
    this.unicodeConverter('text');
  }


  unicodeConverter = (type) => {
    setTimeout(() => {
      if (type === 'text' && this.text) {
        let data = this.text.split('');
        this.decimal = data.map((e) => '00' + e.charCodeAt(0));
        this.decimal = this.decimal.join(' ');
        this.utf16 = this.convertTextToCode(this.text , '00');
        this.utf32 = this.convertTextToCode(this.text , '000000');
        this.utf8text = decodeURIComponent(this.text);
        this.utf8 = this.convertTextToCode(this.text , '');
      }
    }, 200);
  }

  convertTextToCode(v , zero) {
    var B = []; 
    var A = v.length; 
    var E = "";
    var s, y, C; 
    for (var t = 0; t < A; t++) {
      if (this.d(v.charCodeAt(t)) == true) { B.push(v.charAt(t)) } else { 
        s = v.charCodeAt(t); 
        if (s < 56320 && 55295 < s && (t + 1) < A) { y = v.charCodeAt(t + 1); if (y < 57344 && 56319 < y) { s = ((s - 55296) * 1024) + (y - 56320) + 65536; t++ } }
        C = this.p(s); 
        for (var x = 0; x < C.length; x++) { 
          B.push(E); 
          B.push(this.padLeft(C[x].toString(16), 2)) 
        }}
    } 
    let result = [];
    B.map((e) => {
      if (!e) return;
      result.push(zero + e);
    });
    return result.join(' ');
  } 

  p(s) { if (s < 128) { return [s] } else { if (s < 2048) { return [((s & 1984) >>> 6) | 192, (s & 63) | 128] } else { if (s < 65536) { return [((s & 61440) >>> 12) | 224, ((s & 4032) >>> 6) | 128, (s & 63) | 128] } else { if (s < 2097152) { return [((s & 1835008) >>> 18) | 240, ((s & 258048) >>> 12) | 128, ((s & 4032) >>> 6) | 128, (s & 63) | 128] } else { if (s < 67108864) { return [((s & 12582912) >>> 24) | 248, ((s & 16711680) >>> 18) | 128, ((s & 258048) >>> 12) | 128, ((s & 4032) >>> 6) | 128, (s & 63) | 128] } else { if (s < 2147483648) { return [((s & 2147483648) >>> 30) | 252, ((s & 1056964608) >>> 24) | 128, ((s & 16711680) >>> 18) | 128, ((s & 258048) >>> 12) | 128, ((s & 4032) >>> 6) | 128, (s & 63) | 128] } } } } } } return [] }
  d(s) {
    if (s == 9 || s == 10 || s == 13 || s == 32) return true;
    return false;
  }

  padLeft(num, size) {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

}