import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  test;
  constructor(private router: Router) {
    this.test = new Date();
   }

  ngOnInit() {
  }
 naviagterouter(type) {
   if (type === 'c') {
    this.router.navigate(['aboutus']);
   }
 }
 checkpath() {
   return window.location.pathname;
 }
}
