import { Component, OnInit } from '@angular/core';
import 'brace';
import 'brace/mode/sql';
import 'brace/mode/json';
import 'brace/mode/html';


import { ProductService } from '../product.service';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-formatter',
  templateUrl: './formatter.component.html',
  styleUrls: ['./formatter.component.css']
})
export class FormatterComponent implements OnInit {
  output;
  type;
  input;
  loader = false;
  isMobile = false;
  image = '../../assets/img/sql format online.png';

  description = `Format SQL queries online via SQL Query Formatter. One of the Best free online SQL Formatter to beautify your SQL query into a readable query.`;

  keywords = `sql formatter,sql online formatter,online sql formatter, query formatter,sql formatter online`;

  constructor(private productService: ProductService , private router: Router ,
    private titleService: Title , private meta: Meta  ) {
      this.setTitle('Format SQL Query Online | SQL Formatter');
      this.meta.updateTag({ name: 'description', content: this.description });
      this.meta.updateTag({ name: 'keywords', content: this.keywords });

    }

  ngOnInit() {
    if (window.location.pathname === '/online/htmlformatter') {
      this.type = 'html';
      this.input = `<!DOCTYPE html> <html lang="en"> <head> <meta charset="UTF-8"> <title>Home</title></head> <body> This is content. </body> </html>`;
    } else  if (window.location.pathname === '/online/sqlformatter') {
      this.type = 'sql';
      this.input = `SELECT Orders.OrderID, SqlFormatter.CustomerName FROM Orders INNER JOIN SqlFormatter ON Orders.CustomerID = SqlFormatter.CustomerID;`;
    }  else  if (window.location.pathname === '/online/jsonformatter') {
      this.type = 'json';
      this.input = `{"hello":"world", "Test":["hello"]}`;
    } else {
      this.type = 'sql';
    }
  }
  get code () {
    return JSON.stringify(this.output, null, 2);
  }
  onSubmit() {
    this.output = '';
    this.loader = true;
    if (this.type === 'sql') {
      const payload = {
        code : this.input,
        type : this.type,
      };
      this.productService.formatter(payload).subscribe((res: any) => {
        if (res) {
         this.output =  res;
         this.loader = false;
        }
      } , (err) => {
        this.loader = false;
      });
    } else if (this.type === 'json') {
      const payload = {
        code : this.input,
      };
      this.productService.Jsonformatter(payload).subscribe((res: any) => {
        if (res) {
         this.output =  res;
         this.loader = false;
        }
      } , (err) => {
        this.loader = false;
      });
    } else if (this.type === 'html') {
      const payload = {
        code : this.input,
        type : this.type,
      };
      this.productService.formatter(payload).subscribe((res: any) => {
        if (res) {
         this.output =  res;
         this.loader = false;
        }
      } , (err) => {
        this.loader = false;
      });
    }
  }
  set code (v) {
    try {
      this.output = JSON.parse(v);
    } catch (e) {
      console.log('error occored while you were typing the JSON');
    }
  }
  clipboardCopy() {
    setTimeout(() => {
      const textArea = document.createElement('textarea');
      textArea.textContent = this.output;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('Copy');
      textArea.remove();
    }, 200);
  }
  changeformatter(t) {
    this.type = t;
    if (t === 'json') {
      this.setTitle('JSON Formatter');
      this.type = 'json';
      this.input = `{"hello":"world", "Test":["hello"]}`;
    } else if (t === 'sql') {
      this.setTitle('SQL Formatter');
      this.type = 'sql';
      this.input = `SELECT Orders.OrderID, SqlFormatter.CustomerName FROM Orders INNER JOIN SqlFormatter ON Orders.CustomerID = SqlFormatter.CustomerID;`;
    } else if (t === 'html') {
      this.setTitle('HTML Formatter');
      this.type = 'html';
      this.input = `<!DOCTYPE html> <html lang="en"> <head> <meta charset="UTF-8"> <title>Home</title></head> <body> This is content. </body> </html>`;
    }
  }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
