import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-fixedsidebar',
  templateUrl: './fixedsidebar.component.html',
  styleUrls: ['./fixedsidebar.component.css']
})
export class FixedsidebarComponent implements OnInit {
  showsidebar = false;
  menuItems = [];
  constructor(private titleService: Title ) { }

  ngOnInit() {
    this.menuItems = [
      { path: 'online/sqlformatter', title: 'Online Formatter', icon: 'fa fa-user-circle', class: '' },
      { path: 'devnews', title: 'Daily News', icon: 'fa fa-newspaper-o', class: '' },
      { path: 'crontab', title: 'Crontab expression', icon: 'fa fa-superpowers', class: '' },
      { path: 'curl', title: 'Curl Command to Api', icon: 'fa fa-rocket', class: '' },
      { path: 'online/npmpackageanalyzer', title: 'NPM package analyzer', icon: 'fa fa-snowflake-o', class: '' },
      { path: 'lodash_underscore_alternative', title: 'code snippet', icon: 'fa fa-jsfiddle', class: '' },
      { path: 'aboutus', title: 'About Us', icon: 'fa fa-list', class: '' },
      { path: 'regularexpression', title: 'Regular Expression', icon: 'fa fa-paper-plane', class: '' },
      { path: 'online/minifier', title: 'CSS and JS Minifier', icon: 'fa fa-gavel', class: '' },
      { path: 'curl/curl-command', title: 'Curl Command', icon: 'fa fa-trophy', class: '' },
    ];
  }
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  opensidebar() {
    this.showsidebar = !this.showsidebar;
  }
}
