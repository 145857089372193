import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems = [];
  image = '../../assets/img/angular.png';
  constructor(private titleService: Title ) { }
  ngOnInit() {

    this.menuItems = [
      { path: '', title: 'Home', icon: 'fa fa-home', class: '' },
      { path: 'devnews', title: 'Daily News', icon: 'fa fa-newspaper-o', class: '' },
      { path: 'crontab', title: 'Crontab expression', icon: 'fa fa-superpowers', class: '' },
      { path: 'curl', title: 'Curl Command to Api', icon: 'fa fa-rocket', class: '' },
      { path: 'online/npmpackageanalyzer', title: 'NPM package analyzer', icon: 'fa fa-snowflake-o', class: '' },
      { path: 'online/sqlformatter', title: 'SQL Formatter', icon: 'fa fa-user-circle', class: '' },
      { path: 'lodash_underscore_alternative', title: 'code snippet', icon: 'fa fa-jsfiddle', class: '' },
      { path: 'online/minifier', title: 'CSS and JS Minifier', icon: 'fa fa-gavel', class: '' },
      { path: 'unicode-converter', title: 'Unicode Converter', icon: 'fa fa-fighter-jet', class: '' },
      { path: 'curl/curl-command', title: 'Curl Command', icon: 'fa fa-trophy', class: '' },
      { path: 'regularexpression', title: 'Regular Expression', icon: 'fa fa-paper-plane', class: '' },
      { path: 'aboutus', title: 'About Us', icon: 'fa fa-list', class: '' },
    ];
  }
public setTitle( newTitle: string) {
  if (newTitle === 'Home') {
    newTitle = 'DevTools For Web Developer and  Online Web Development Tools';
  }
  if (newTitle ===  `code snippet` ) {
    newTitle = 'Useful Lodash and underscore alternative';
  }
  if (newTitle === 'JWT Decoder') {
    this.onNavigate('https://www.jstoolset.com/jwt');
  }
  if (newTitle === 'Diff Viewer') {
    this.onNavigate('https://diffviewer.onlinedevtools.in');
  }
  this.titleService.setTitle( newTitle );
}
onNavigate(url) {
  window.open(url , '_blank');
}
}
