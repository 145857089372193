import { Routes } from '@angular/router';

import { FormatterComponent } from './formatter/formatter.component';
import { BeautifierComponent } from './beautifier/beautifier.component';
import { CrontabComponent } from './crontab/crontab.component';
import { CurlComponent } from './curl/curl.component';
import { RegularexpressionComponent } from './regularexpression/regularexpression.component';
import { ConverterComponent } from './converter/converter.component';
import { NewsfeedComponent } from './newsfeed/newsfeed.component';
import { MiniferComponent } from './minifer/minifer.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PureJavascriptComponent } from './privacypolicy/purejavascript.component';
import { HomeComponent } from 'src/app/home/home.component';
import { UnicodeConverterComponent } from './unicode-converter/unicode-converter.component';
import { YoutubeVideoDownloaderComponent } from './youtube-video-downloader/youtube-video-downloader.component';



export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
        component: HomeComponent

    },
    {
        path: 'online/npmpackageanalyzer',
        component: BeautifierComponent
    },
    {
        path: 'crontab',
        component: CrontabComponent
    },  {
        path: 'aboutus',
        component: AboutusComponent
    },
    {
     path: 'lodash_underscore_alternative',
     component: PureJavascriptComponent
    },
    {
        path: 'curl',
        component: CurlComponent
    },
    {
        path: 'regularexpression',
        component: RegularexpressionComponent
    },
    {
        path: 'curl/curl-command',
        component: ConverterComponent
    },
    {
        path: 'devnews',
        component: NewsfeedComponent
    },
    {
        path: 'online/sqlformatter',
        component: FormatterComponent
    } , {
        path : 'online/minifier',
        component: MiniferComponent
    }
    , {
        path: 'unicode-converter',
        component: UnicodeConverterComponent
    }, {
        path: 'youtubefreedownload',
        component: YoutubeVideoDownloaderComponent
    },
];
