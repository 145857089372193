import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.css']
})
export class NewsfeedComponent implements OnInit {
  newsList = [];
  isMobile = false;
  pageSize = 3;
  language = 'daily_js';
  description = `We are one of the leading and most visited Website For Developers and Coders - Online Developer Tools`;
  keyword = `useful devnews , devnews , developer news , tech news`;
  heading = `Website For Developers and Coders | Online Dev Tools`;
  constructor(private productService: ProductService ,
    private title: Title, private meta: Meta) {
      this.title.setTitle(this.heading);
      this.meta.updateTag({ name: 'description', content: this.description });
      this.meta.updateTag({ name: 'keywords', content: this.keyword });
    }

  ngOnInit() {
    this.getnews();
    if (document.documentElement.clientWidth > 768) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  changeLimit() {
    this.newsList = [];
    this.getnews();
  }
  getnews() {
    const payload: any = {
      size : this.pageSize
    };
    if (this.language) {
      payload.pub = this.language;
    } else {
      delete payload.pub;
    }
    this.productService.dailynews(payload).subscribe((res: any) => {
      this.newsList = res;
    });
  }

  onNavigate(url) {
    window.open(url , '_blank');
  }

}
