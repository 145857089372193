import { Component, OnInit } from '@angular/core';




import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-converter',
  templateUrl: './converter.component.html',
  styleUrls: ['./converter.component.css']
})
export class ConverterComponent implements OnInit {
  
  image = '../../assets/img/angular.png';

  title = 'How To Use cURL Command in Linux and Unix With cURL Options?';
  description = `We Provide You Collection of Most Used cURL command in Linux and Unix with Curl command Options. We Explain More Linux Command Using cURL With Examples.`;
  keyword = `cURL command , cURL command in Linux , cURL command in Unix`;

  output = `
  // cURL Verision
  curl --version

  // Download File Using cURL
  curl -O https://onlinedevtools.in/sitemap.xml 
  
  // Download Multiple Files
  curl -O https://onlinedevtools.in/curl -O https://onlinedevtools.in/ 
  
  //cURL Post Request
  curl --data "firstName=Jose&lastName=Steve "https://onlinedevtools.in/ 
  
  // Set UserAgent using cURL
  curl -I angular.io --user-agent "I am a new User-Agent" 
  
  //Store Website Cookies
  curl --cookie-jar cnncookies.txt"https://onlinedevtools.in/ -O`;

  curlRun = `
  1. Copy the curl command

  2. Open Terminal your System
  
  3.Paste the cURL Command in Terminal
  
  4.Press Enter Key`;

  constructor(private meta: Meta , private head: Title) {
    this.head.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: this.description });
    this.meta.updateTag({ name: 'keywords', content: this.keyword });
   }

  ngOnInit() {
  }

}
