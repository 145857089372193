import { Component, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-regularexpression',
  templateUrl: './regularexpression.component.html',
  styleUrls: ['./regularexpression.component.css']
})
export class RegularexpressionComponent implements OnInit {
  input = `(a|b)*abb`;
  testcase = `abb`;
  output;
  pattern = `[0-9]*`;
  code = `developers 1, Give suggestions  and your suggestions were taken it as a welcome one 2 our website.`;
  result;
  loader = false;

  description = `Normally we are using re (regular expression) in python. we provide an example for  re search(), re match() and re.findall()`;

  keyword = `regex tester , regex , regular expression , Test your regex , regex pattern , Online Dev Tools` ;
  image = '../../assets/img/angular.png';

  example = `import re

  #Check if the text starts with "MY" and ends with "Python":
  
  text = "MY Fav Programming Langulage is Python"
  output = re.search("^MY.*Python$", text)
  
  if output:
    print("YES! Match Found!")
  else:
    print("No!! Match")  
  `;

  constructor(private productService: ProductService ,
    private meta: Meta , private title : Title) {
     this.title.setTitle(`Python re search()  | Regular Expression`);
     this.meta.updateTag({ name: 'description', content: this.description });
     this.meta.updateTag({ name: 'keywords', content: this.keyword });
    }

  ngOnInit() {
  }
  checkRegularexpress() {
    const payload = {
      type : this.testcase,
      code : this.input,
    };
    this.output = null;
    this.productService.checkexpress(payload).subscribe((res: any) => {
      if (res) {
        this.output = 'Yes';
      } else {
        this.output = 'No';
      }
    });
  }
  runRegularexpress() {
    const payload = {
      expression: this.pattern,
      code: this.code,
    };
    if (this.pattern && this.code) {
      this.loader = true;
      this.result = null;
      this.productService.testexpress(payload).subscribe((res: any) => {
        this.result = res;
        this.loader = false;
      }, (err) => {
        this.loader = false;
      });
    }
  }
}
