import { Component, OnInit } from '@angular/core';
import 'brace/mode/javascript';
import { ProductService } from '../product.service';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-purejavascript',
  templateUrl: './purejavascript.component.html',
  styleUrls: ['./purejavascript.component.css']
})
export class PureJavascriptComponent implements OnInit {
  temp;
  image = '../../assets/img/angular.png';
  title = 'Lodash Alternative | Underscore Alternative ';
  metaData = `We are providing pure javascript function for lodash and Underscore alternative with code snippet`;
  keyword = 'lodash alternative, underscore alternative';

  soryby = `
  var members = [
    { 'member': 'ball',   'number': 5 },
    { 'member': 'apple', 'number': 10 },
    { 'member': 'orange',   'number': 2 },
    { 'member': 'cat', 'number': 3 }
  ];
   
  _.sortBy(members, [function(o) { return o.member; }]);
  
  // [['apple', 10], ['ball', 5], ['cat', 3], ['orange', 2]]
   
  _.sortBy(members, ['member', 'number']);
   
   // [['apple', 2], ['ball', 3], ['cat', 5], ['orange', 10]]`;
  map = `
  var members = [
    { 'member': 'ball' },
    { 'member': 'orange' }
  ];
   
  _.map(members, 'member');
  
  // ['ball', 'orange']
  `;

  constructor(private product: ProductService , private meta: Meta ,
    private head: Title) {
    this.head.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: this.metaData });
    this.meta.updateTag({ name: 'keywords', content: this.keyword });
  }

  ngOnInit() {
    this.temp = [];
    this.product.getNolodash().subscribe((res: any) => {
       this.temp = JSON.parse(res.data);
    });

  }
    goTo(id) {
        const elmnt = document.getElementById(id);
        elmnt.scrollIntoView({
          behavior: 'smooth'
        });
    }
}
