import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  title = 'About US  | Online Dev Tools';
  metaData = `Founded in 2019 by Stephen Prakash, It has its headquarters in Bangalore, Karnataka, India.Stephen Praksh - Chief Executive Officer At Online Dev Tools`;
  keyword = 'online dev tools , devtools , developer tools';
  constructor(private meta: Meta ,
    private head: Title) {
      this.head.setTitle(this.title);
      this.meta.updateTag({ name: 'description', content: this.metaData });
      this.meta.updateTag({ name: 'keywords', content: this.keyword });
     }

  ngOnInit() {
  }

}
